import React, { useEffect } from 'react';
import { Paper, Typography, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getEvent } from '../../actions/events';

import useStyles from './styles';

export const EventDetails = () => {
  const { event, isLoading } = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getEvent(id));
  }, [id, dispatch]);

  if(!event) return null;

  if(isLoading) {
    return <Paper elevation={6}>
      <CircularProgress size="7em" />
    </Paper>
  }

  return (
    <Paper style={{ padding: '20px', borderRadius: '15px' }} elevation={6}>
        <div className={classes.card}>
          <div className={classes.section}>
            <Typography variant="h4" component="h4">Name: {event.name}</Typography>
            <Typography variant="body2">Species: {event.species}</Typography>
            <Typography variant="body2">Locations: {event.locations}</Typography>
            <Typography variant="body2">Description: {event.description}</Typography>
            <Typography variant="body2">Reason: {event.reason}</Typography>
          </div>
        </div>
    </Paper>
  )
}