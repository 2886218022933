import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';

import Hotspot from './Hotspot/Hotspot';

export const Hotspots = () => {
    const { hotspots, isLoading } = useSelector((state) => state.hotspots);

    if(!hotspots.length & !isLoading) return 'No hotspots';

    

    return (
        isLoading ? <CircularProgress />: (
            <Grid container alignItems="stretch" spacing={3}>
                {hotspots.map((item) => (
                    <Grid key={item._id} item xs={12} sm={6}>
                        <Hotspot hotspotInfo={item} />
                    </Grid>
                ))}
            </Grid>
        )
    )
}