import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';

import Event from './Event/Event';

export const Events = () => {
    const { events, isLoading } = useSelector((state) => state.events);

    if(!events.length & !isLoading) return 'No events';

    return (
        isLoading ? <CircularProgress />: (
            <Grid container alignItems="stretch" spacing={3}>
                {events.map((item) => (
                    <Grid key={item._id} item xs={12} sm={6}>
                        <Event eventInfo={item} />
                    </Grid>
                ))}
            </Grid>
        )
    )
}