import React from "react";
import { MapContainer, CircleMarker, TileLayer,Tooltip,Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { Link } from 'react-router-dom';

// Try https://github.com/stefanocudini/leaflet-list-markers
// https://stackoverflow.com/questions/22081680/get-a-list-of-markers-layers-within-current-map-bounds-in-leaflet
// Or try itnersection observer api on https://plotly.com/javascript/bubble-maps/
// https://dash.plotly.com/interactive-graphing update graphs on hover
const MapChart = ({data}) => {

  // TODO for alewife (2412740) there are a lot of duplicate hotspots (304) being returned with same lat/long and h3 geo tag
  var centerLat = (data.minLat + data.maxLat) / 2;
  var distanceLat = data.maxLat - data.minLat;
  var bufferLat = distanceLat * 0.05;
  var centerLong = (data.minLong + data.maxLong) / 2;
  var distanceLong = data.maxLong - data.minLong;
  var bufferLong = distanceLong * 0.05;


  // TODO: When a marker leaves or comes into view, update the content in the time chart:
  // https://dev.to/producthackers/intersection-observer-using-react-49ko
  // https://medium.com/@ryanfinni/the-intersection-observer-api-practical-examples-7844dfa429e9

  return (
    <MapContainer
      style={{ height: "480px", width: "100%" }}
      zoom={1}
      center={[centerLat, centerLong]}
      bounds={[
        [data.minLat - bufferLat, data.minLong - bufferLong],
        [data.maxLat + bufferLat, data.maxLong + bufferLong]
      ]}
  >
    <TileLayer url="https://tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'/>

    {data.locations.map((hotspot, k) => {
      return (
        <CircleMarker
          key={k}
          center={[hotspot["coordinates"][0], hotspot["coordinates"][1]]} // Longitude is first in the data but it must be presented second in the set
          radius={20 * Math.log(hotspot["count"])}
          fillOpacity={0.5}
          stroke={false}
          interactive={true}
        >
          <Tooltip direction="right" offset={[-8, -2]} opacity={1} >
            <span>{`Location: ${hotspot["coordinates"]}`}</span>
            <br></br>
            <span>{`Count: ${hotspot["count"]}`}</span>
            <br></br>
            <span>Click for more info</span>
          </Tooltip>
          <Popup><a href="http://www.google.com">Click Me</a></Popup>
        </CircleMarker>
      );
    })}
  </MapContainer>
  );
};

export default MapChart;