import { FETCH_ALL, FETCH, START_LOADING, END_LOADING } from '../constants/actionTypes'
import * as api from '../api';

export const getMultiSpecies = (page, searchQuery) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getMultiSpecies(page, searchQuery);

        dispatch({ type: FETCH_ALL, payload: data});
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log(error);
    }
}

export const getOneSpecies = (id) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getOneSpecies(id);

        dispatch({ type: FETCH, payload: data});
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log(error);
    }


}