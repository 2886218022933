import React from 'react';
import { Grid, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';

import Species from './Species/Species';

export const MultiSpecies = () => {
    const { species, isLoading } = useSelector((state) => state.species);

    if(!species.length & !isLoading) return 'No species';
    // https://www.youtube.com/watch?v=x7niho285qs&t=1s
    return (
        isLoading ? <CircularProgress />: (
            <Grid container alignItems="stretch" spacing={3}>
                {species.map((item) => (
                    <Grid key={item._id} item xs={12} sm={6}>
                        <Species speciesInfo={item} />
                    </Grid>
                ))}
            </Grid>
        )
    )
}