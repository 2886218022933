import React, { useEffect } from 'react';
import { Container, Grow, Grid, Paper } from '@material-ui/core';

import EventsPagination from './EventsPagination';
import { Events } from "./Events";
import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router-dom';

import { getEvents } from '../../actions/events';


function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export const EventsPage = (props) => {
    const query = useQuery();
    const page = query.get('page') || 1;

    const dispatch = useDispatch();

    // Only run once, paginatino page will take over once vars are populated
    useEffect(() => {
        dispatch(getEvents(page));
    }, [page, dispatch]);

    return (
        <Grow in>
            <Container>
                <Grid container justifyContent="space-between" alignItems="stretch" spacing={3}>
                    <Grid item xs={12} sm={10}>
                        <Events />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Paper elevation={6}>
                            <EventsPagination page={page} />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Grow>
    )
}