import React, { useEffect } from 'react';
import { Container, Grow, Grid, Paper } from '@material-ui/core';

import HotspotsPagination from './HotspotsPagination';
import { Hotspots } from "./Hotspots";
import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router-dom';

import { getHotspots } from '../../actions/hotspots';


function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export const HotspotsPage = (props) => {
    const query = useQuery();
    const page = query.get('page') || 1;

    const dispatch = useDispatch();

    // Only run once, pagination page will take over once vars are populated
    useEffect(() => {
        dispatch(getHotspots(page));
    }, [page, dispatch]);

    return (
        <Grow in>
            <Container>
                <Grid container justifyContent="space-between" alignItems="stretch" spacing={3}>
                    <Grid item xs={12} sm={10}>
                        <Hotspots />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Paper elevation={6}>
                            <HotspotsPagination page={page} />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Grow>
    )
}