import { BrowserRouter, Routes, Route} from 'react-router-dom';
import React, {useEffect, useState} from 'react';

import { Home } from "./components/Home/Home";
import { About } from "./components/about";
import { Navigation } from "./components/navigation";

import { MultiSpeciesPage } from "./components/MultiSpecies/MultiSpeciesPage";
import { SpeciesDetails } from "./components/Details/SpeciesDetails";

import { EventsPage } from "./components/Events/EventsPage";
import { EventDetails } from "./components/Details/EventDetails";

import { HotspotsPage } from "./components/Hotspots/HotspotsPage";
import { HotspotDetails } from "./components/Details/HotspotDetails";

import SmoothScroll from "smooth-scroll";
import "./App.css";

import JsonData from "./data/data.json";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// https://medium.com/@joeponzio/the-right-way-to-add-firebase-to-your-react-project-using-react-hooks-a32db20bf1a0



const App = () => {

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  const [lat, setLat] = useState(null);
  const [lng, setLng] = useState(null);

  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat(position.coords.latitude);
      setLng(position.coords.longitude);
    });
  }

  return (
    <BrowserRouter>
      <Navigation />
      <Routes>
        {/* <Route path="*" element={<NotFound />} /> */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about" element={<About data={landingPageData.About} />} />
        <Route exact path="/events" element={<EventsPage lat={lat} lng={lng} />} />
        <Route path="/events/:id" element={<EventDetails />} />
        <Route exact path="/hotspots" element={<HotspotsPage lat={lat} lng={lng} />} />
        <Route path="/hotspots/:id" element={<HotspotDetails />} />
        <Route exact path="/species" element={<MultiSpeciesPage lat={lat} lng={lng} />} />
        <Route path="/species/:id" element={<SpeciesDetails />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;