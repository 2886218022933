import React, { useEffect, useState } from 'react';
import { Container, Grow, Grid, Paper } from '@material-ui/core';

import MultiSpeciesPagination from './MultiSpeciesPagination';
import { MultiSpecies } from "./MultiSpecies";
import { useDispatch } from 'react-redux';

import { useLocation } from 'react-router-dom';

import { getMultiSpecies } from '../../actions/species';

import SearchBar from "material-ui-search-bar";
// import {SpeciesData} from 'SpeciesData.json';
// For autocomplete: https://teamwertarbyte.github.io/material-ui-search-bar/

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

export const MultiSpeciesPage = (props) => {
    const query = useQuery();
    const page = query.get('page') || 1;
    const [searchQuery, setSearchQuery] = useState('');

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getMultiSpecies(page, searchQuery));
    }, [page, dispatch]);

    return (
        <Grow in id="species_page">
            <Container>
                <Grid container justifyContent="space-between" alignItems="stretch" spacing={3}>
                    <Grid>
                        <SearchBar id="search" 
                            value={searchQuery}
                            onChange={(newValue) => setSearchQuery(newValue)}
                            placeholder="Enter a species e.g. Mola mola"
                            onRequestSearch={() => dispatch(getMultiSpecies(page, searchQuery))}
                        />
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <MultiSpecies />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Paper elevation={6}>
                            <MultiSpeciesPagination page={page} />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Grow>
    )
}