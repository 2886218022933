import React from 'react';
import { Card, CardContent, Typography, ButtonBase } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';


const Species = ({ speciesInfo }) => {
    const history = useNavigate();
    const openSpecies = () => history(`/species/${speciesInfo.taxonKey}`);

    return (
        <Card raised elevation={6}>
            <ButtonBase onClick={openSpecies}>
                <CardContent>
                    <Typography variant="body2">Name: {speciesInfo.name}</Typography>
                    <Typography variant="body2">Species: {speciesInfo.species}</Typography>
                    <Typography variant="body2">Total Observations: {speciesInfo.count.toLocaleString()}</Typography>
                </CardContent>
            </ButtonBase>
        </Card>
    );
};

export default Species;