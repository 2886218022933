import React, { useEffect } from 'react';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getHotspots } from '../../actions/hotspots';
import useStyles from '../styles';

const HotspotsPagination = ({ page }) => {
    const { numberOfPages } = useSelector((state) => state.hotspots);
    const classes = useStyles();
    const dispatch = useDispatch();
        
    useEffect(() => {
        if(page) dispatch(getHotspots(page));
    }, [page, dispatch]);

    return (
        <Pagination 
            classes={{ ul: classes.ul }}
            count={numberOfPages}
            page={Number(page) || 1}
            variant="outlined"
            color="primary"
            renderItem={(item) => (
                <PaginationItem { ...item } component={Link} to={`/hotspots?page=${item.page}`} />
            )}
        />
    );
};

export default HotspotsPagination;