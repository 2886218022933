import { FETCH_ALL, FETCH, START_LOADING, END_LOADING } from '../constants/actionTypes'
import * as api from '../api';

export const getEvents = (page, taxonKey) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getEvents(page, taxonKey);

        dispatch({ type: FETCH_ALL, payload: data});
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log(error);
    }
}

export const getEvent = (id) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getEvent(id);

        dispatch({ type: FETCH, payload: data});
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log(error);
    }


}