import React, { useEffect, useState } from 'react';

import { Navigation } from "../navigation";
import { Header } from "../header";
import { Features } from "../features";
import { Services } from "../services";
import { Contact } from "../contact";

import JsonData from "../../data/data.json";



export const Home = () => {

    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);

    return (
        <div>
          <Navigation />
          <Header data={landingPageData.Header} />
          <Features data={landingPageData.Features} />
          <Services data={landingPageData.Services} />
          <Contact data={landingPageData.Contact} />
        </div>
      )
}