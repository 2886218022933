import React, { useEffect } from 'react';
import { Paper, Typography, CircularProgress } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getHotspot } from '../../actions/hotspots';

import useStyles from './styles';

export const HotspotDetails = () => {
  const { hotspot, isLoading } = useSelector((state) => state.hotspots);
  const dispatch = useDispatch();
  const { id } = useParams();
  const classes = useStyles();

  useEffect(() => {
    dispatch(getHotspot(id));
  }, [id, dispatch]);

  if(!hotspot) return null;

  if(isLoading) {
    return <Paper elevation={6}>
      <CircularProgress size="7em" />
    </Paper>
  }

  return (
    <Paper style={{ padding: '20px', borderRadius: '15px' }} elevation={6}>
        <div className={classes.card}>
          <div className={classes.section}>
            <Typography variant="h4" component="h4">Taxon Key: {hotspot.taxonKey}</Typography>
            <Typography variant="body2">Location: {hotspot.location}</Typography>
            <Typography variant="body2">Count: {hotspot.count}</Typography>
            <Typography variant="body2">Monthly Distribution: {hotspot.month_dist}</Typography>
          </div>
        </div>
    </Paper>
  )
}