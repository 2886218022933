import { combineReducers } from 'redux';

import speciesReducer from './species';
import eventsReducer from './events';
import hotspotsReducer from './hotspots';

export default combineReducers({ 
    species: speciesReducer,
    events: eventsReducer,
    hotspots: hotspotsReducer,
});