import React from 'react';
import { Pagination, PaginationItem } from '@material-ui/lab';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import useStyles from '../styles';

const MultiSpeciesPagination = ({ page }) => {
    const { numberOfPages } = useSelector((state) => state.species);
    const classes = useStyles();

    return (
        <Pagination 
            classes={{ ul: classes.ul }}
            count={numberOfPages}
            page={Number(page) || 1}
            variant="outlined"
            color="primary"
            renderItem={(item) => (
                <PaginationItem { ...item } component={Link} to={`/species?page=${item.page}`} />
            )}
        />
    );
};

export default MultiSpeciesPagination;