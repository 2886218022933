import React, {useState} from 'react';

export const Header = (props) => {

  const [isHoveringEvent, setIsHoveringEvent] = useState(false);

  const handleMouseOverEvent = () => {
    setIsHoveringEvent(true);
  };

  const handleMouseOutEvent = () => {
    setIsHoveringEvent(false);
  };

  const [isHoveringHotspot, setIsHoveringHotspot] = useState(false);

  const handleMouseOverHotspot = () => {
    setIsHoveringHotspot(true);
  };

  const handleMouseOutHotspot = () => {
    setIsHoveringHotspot(false);
  };

  const [isHoveringSpecies, setIsHoveringSpecies] = useState(false);

  const handleMouseOverSpecies = () => {
    setIsHoveringSpecies(true);
  };

  const handleMouseOutSpecies = () => {
    setIsHoveringSpecies(false);
  };

  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-8 col-md-offset-2 intro-text'>
                <h1>
                  {props.data ? props.data.title : 'Loading'}
                  <span></span>
                </h1>
                <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                <a
                  href='/events'
                  className='btn btn-custom btn-lg page-scroll'
                  onMouseOver={handleMouseOverEvent} onMouseOut={handleMouseOutEvent}
                >
                  {isHoveringEvent ? 'Search Events':'Events'}
                </a>
                <a
                  href='/hotspots'
                  className='btn btn-custom btn-lg page-scroll'
                  onMouseOver={handleMouseOverHotspot} onMouseOut={handleMouseOutHotspot}
                >
                  {isHoveringHotspot ? 'Search Hotspots':'Hotspots'}
                </a>
                <a
                  href='/species?page=1'
                  className='btn btn-custom btn-lg page-scroll'
                  onMouseOver={handleMouseOverSpecies} onMouseOut={handleMouseOutSpecies}
                >
                  {isHoveringSpecies ? 'Search Species':'Species'}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
