import React from 'react';
import { Card, CardContent, Typography, ButtonBase } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';


const Event = ({ eventInfo }) => {
    const history = useNavigate();
    const openEvent = () => history(`/events/${eventInfo._id}`);

    return (
        <Card raised elevation={6}>
            <ButtonBase onClick={openEvent}>
                <CardContent>
                    <Typography variant="body2">Name: {eventInfo.name}</Typography>
                    <Typography variant="body2">Species: {eventInfo.species}</Typography>
                    <Typography variant="body2">Locations: {eventInfo.locations}</Typography>
                </CardContent>
            </ButtonBase>
        </Card>
    );
};

export default Event;